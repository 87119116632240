import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

import { Box, Container, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn, isInitialized } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isInitialized) return false;
    if (!isLoggedIn) {
      navigate('login', { replace: true });
    }
  }, [isInitialized, isLoggedIn, navigate]);

  if (!isInitialized || !isLoggedIn)
    return (
      <Box position="relative" height="calc(var(--vh, 1vh) * 100)">
        <LinearProgress color="primary" sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} />
        <Container maxWidth="sm" sx={{ pt: '16px', height: '100%' }}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography letterSpacing="-0.3px">사용자 정보를 확인 중 입니다.</Typography>
          </Box>
        </Container>
      </Box>
    );

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
