/*
 * Side Bar - 정산 관리
 */

import { IconMathSymbols } from '@tabler/icons';

// ==============================|| CALCULATE MENU ITEMS ||============================== //

const calculate = {
  id: 'calculateTab',
  title: '정산관리',
  type: 'group',
  children: [
    {
      id: 'calculateManage',
      title: '정산관리',
      type: 'item',
      url: '/calculate',
      icon: IconMathSymbols
    },
    {
      id: 'calculateHistory',
      title: '정산내역',
      type: 'item',
      url: '/calculate-history',
      icon: IconMathSymbols
    }
  ]
};

export default calculate;
