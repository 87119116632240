// project imports
import PageNotFound from '../views/error/PageNotFound';

// ==============================|| MAIN ROUTING ||============================== //

/**
 * 에러화면 라우팅
 * @type {{path: string, element: JSX.Element}}
 *
 * @autho hkcho
 */
const ErrorRoutes = {
  path: '/error/404',
  element: <PageNotFound />
};
export default ErrorRoutes;
