/*
 * Side Bar - 매장 관리
 */

// assets
import { IconBuildingStore } from '@tabler/icons';

// ==============================|| VENDOR MENU ITEMS ||============================== //

const vendor = {
  id: 'vendorTab',
  title: '',
  type: 'group',
  children: [
    {
      id: 'vendorManage',
      title: '매장 관리',
      type: 'item',
      url: '/vendor',
      icon: IconBuildingStore
    }
  ]
};

export default vendor;
