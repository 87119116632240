/*
 * Side Bar - 주문 관리
 */

// assets
import { IconArrowBackUp, IconBasket, IconClipboardCheck, IconClipboardX, IconReplace } from '@tabler/icons';

// ==============================|| productManage MENU ITEMS ||============================== //

const order = {
  id: 'orderTab',
  title: '주문관리',
  type: 'group',
  children: [
    {
      id: 'orderAll',
      title: '전체주문내역',
      type: 'item',
      url: '/order/all',
      icon: IconBasket,
      breadcrumbs: true
    },
    {
      id: 'orderManage',
      title: '주문 관리',
      type: 'item',
      url: '/order',
      icon: IconClipboardCheck,
      breadcrumbs: true
    },
    {
      id: 'orderCancelManage',
      title: '주문취소 관리',
      type: 'item',
      url: '/order/cancel',
      icon: IconClipboardX,
      breadcrumbs: true
    },
    {
      id: 'exchangeManage',
      title: '교환 관리',
      type: 'item',
      url: '/order/exchange',
      icon: IconReplace,
      breadcrumbs: true
    },
    {
      id: 'returnManage',
      title: '반품 관리',
      type: 'item',
      url: '/order/return',
      icon: IconArrowBackUp,
      breadcrumbs: true
    }
  ]
};

export default order;
