import React from 'react';
// routing
import Routes from 'routes';

// project imports
import ThemeCustomization from 'themes';

import { WineOneProvider } from './contexts/WineOneContext';
import { WineOneAuthProvider as AuthProvider } from 'contexts/AuthContext';

import { useDispatch } from './store';
import { setCodeVersion } from 'store/slices/wo-constants';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { createBrowserHistory } from 'history';

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    // 1km wine 코드정보 가져오기
    dispatch(setCodeVersion(process.env.REACT_APP_WO_CODE_VERSION));
  }, []);

  return (
    <WineOneProvider>
      <ThemeCustomization>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeCustomization>
    </WineOneProvider>
  );
};

export default App;

export const history = createBrowserHistory();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

if (process.env.REACT_APP_FIREBASE_DATABASE_URL) {
  firebaseConfig.databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
}

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(firebaseApp);

// iOS Safari 100vh issue
// 100vh -> calc(var(--vh, 1vh) * 100)
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Axios authentication header setting.
axios.interceptors.request.use(async (config) => {
  const auth = getAuth();
  if (auth.currentUser) {
    let accessToken = auth.currentUser.accessToken;
    const { stsTokenManager } = auth.currentUser;

    // 만료된 토큰일 경우 토큰 갱신
    if (stsTokenManager.isExpired) {
      console.warn('[axios][request.interceptor] 토큰이 만료된 vendor 사용자. 토큰을 갱신합니다.');
      accessToken = await auth.currentUser.getIdToken(true);
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});
