import { Navigate, useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

import { ORDER_PATH } from '../config';
import ErrorRoutes from './ErrorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: '/', element: <Navigate to={ORDER_PATH} replace /> },
    LoginRoutes,
    MainRoutes,
    ErrorRoutes,
    {
      path: '*',
      element: <Navigate to="/error/404" replace />
    }
  ]);
}
