/*
 * Side Bar - 메뉴 관리
 */

import product from './product';
import promotion from './promotion';
import order from './order';
import calculate from './calculate';
import vendor from './vendor';
import inquiry from './inquiry';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [product, promotion, order, calculate, vendor, inquiry]
};

export default menuItems;
