// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  bottomNav: {
    value: '',
    show: false
  }
};

const wineOne = createSlice({
  name: 'wineOne',
  initialState,
  reducers: {

  }
});

// Reducer
export default wineOne.reducer;