/*
 * Side Bar - 상품 관리
 */

// material-ui
import { IconGlass, IconGlassFull } from '@tabler/icons';

// ==============================|| PRODUCT MENU ITEMS ||============================== //

const product = {
  id: 'productTab',
  title: '상품관리',
  type: 'group',
  children: [
    {
      id: 'productManage',
      title: '전체 상품 관리',
      type: 'item',
      url: '/product',
      icon: IconGlass,
      breadcrumbs: true
    },
    {
      id: 'recommendProductManage',
      title: '추천 상품 관리',
      type: 'item',
      url: '/product/recommend',
      icon: IconGlassFull,
      breadcrumbs: true
    }
  ]
};

export default product;
