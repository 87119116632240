// action - state management
import { LOGIN, LOGOUT } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  vendor: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const { user, vendor } = action.payload;

      if (vendor?.biz?.name) {
        document.title = `1KMWINE 사장님 - ${vendor.biz.name}`;
      }

      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
        vendor
      };
    }
    case LOGOUT: {
      document.title = `1KMWINE 사장님 `;
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        vendor: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
