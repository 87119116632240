import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// product routing
const ProductListPage = Loadable(lazy(() => import('views/product/ProductList')));
const ProductDetail = Loadable(lazy(() => import('views/product/ProductDetail/ProductDetail')));
const ProductCreate = Loadable(lazy(() => import('views/product/ProductCreate/ProductCreate')));
const ProductUpdate = Loadable(lazy(() => import('views/product/ProductUpdate/ProductUpdate')));
const RecommendProduct = Loadable(lazy(() => import('views/product/RecommendProduct')));

// 공동구매 routing
const CoBuyingList = Loadable(lazy(() => import('views/promotion/CoBuying/CoBuyingList')));
const CoBuyingDetail = Loadable(lazy(() => import('views/promotion/CoBuying/CoBuyingDetail')));

// 오늘의 한 병 routing
const TodayOneBottleList = Loadable(lazy(() => import('views/product/TodayOneBottle')));
const TodayOneBottleDetail = Loadable(
  lazy(() => import('views/product/TodayOneBottle/components/TodayOneBottleDetail/TodayOneBottleDetail'))
);

// promotion routing
const PromotionList = Loadable(lazy(() => import('views/promotion/PromotionList')));
const PromotionCreate = Loadable(lazy(() => import('views/promotion/PromotionCreate')));
const PromotionUpdateLayout = Loadable(lazy(() => import('views/promotion/PromotionUpdate/PromotionUpdateLayout')));

// 플랫폼 프로모션 routing
const EventPromotionList = Loadable(lazy(() => import('views/benefit/eventPromotion/EventPromotionList')));
const EventPromotionDetail = Loadable(lazy(() => import('views/benefit/eventPromotion/EventPromotionDetail')));

// order routing
const Order = Loadable(lazy(() => import('views/order/List/index')));
const OrderAll = Loadable(lazy(() => import('views/order/AllList/index')));
const OrderCancel = Loadable(lazy(() => import('views/order/CancelList/index')));
const OrderExchange = Loadable(lazy(() => import('views/order/ExchangeList/index')));
const OrderReturn = Loadable(lazy(() => import('views/order/RefundList/index')));
const OrderDetail = Loadable(lazy(() => import('views/order/detail')));

// calculate routing
const Calculate = Loadable(lazy(() => import('views/calculate/index')));
const CalculateHistory = Loadable(lazy(() => import('views/calculate/history/index')));
const CalculateHistoryDetail = Loadable(lazy(() => import('views/calculate/history/detail')));

// vendor routing
const Vendor = Loadable(lazy(() => import('views/vendor/index')));

// benefit routing
const Coupon = Loadable(lazy(() => import('views/benefit/coupon/index')));
const CouponCreate = Loadable(lazy(() => import('views/benefit/coupon/CouponCreate')));
const CouponDetail = Loadable(lazy(() => import('views/benefit/coupon/CouponDetail')));

// notice routing
const Notice = Loadable(lazy(() => import('views/notice/index')));
const NoticeDetail = Loadable(lazy(() => import('views/notice/NoticeDetail')));

// notification
const NotificationList = Loadable(lazy(() => import('views/notification/index')));

// my
const My = Loadable(lazy(() => import('views/my/index')));

// statistics
const Statistics = Loadable(lazy(() => import('views/statistics/index')));

// faq

// inquiry
const Inquiry = Loadable(lazy(() => import('views/inquiry/index')));
const InquiryDetail = Loadable(lazy(() => import('views/inquiry/InquiryDetail')));
const InquiryCreate = Loadable(lazy(() => import('views/inquiry/InquiryCreate')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      // 대시보드
      path: '/dashboard/default',
      element: <DashboardDefault />
    },
    {
      // 상품 관리
      path: '/product',
      // element: <Product />
      element: <ProductListPage />
    },
    {
      // 상품 관리 > 상품 상세
      path: '/product/detail/:id',
      element: <ProductDetail />
    },
    {
      // 상품 관리 > 상품 추가
      path: '/product/create',
      element: <ProductCreate />
    },
    {
      // 상품 관리 > 상품 상세 > 상품 수정
      path: '/product/update/:id',
      element: <ProductUpdate />
    },
    {
      // 공동구매 목록
      path: '/promotion/co-buying',
      element: <CoBuyingList />
    },
    // 공동구매 상세
    {
      path: '/promotion/co-buying/:id',
      element: <CoBuyingDetail />
    },
    {
      // 오늘의 한 병 목록
      path: '/product/today-one-bottle',
      element: <TodayOneBottleList />
    },
    {
      // 오늘의 한 병 상세
      path: '/product/today-one-bottle/detail',
      element: <TodayOneBottleDetail />
    },
    {
      // 추천 상품 관리
      path: '/product/recommend',
      element: <RecommendProduct />
    },
    {
      // 프로모션 관리
      path: '/self-promotion',
      element: <PromotionList />
    },
    {
      // 프로모션 관리 > 프로모션 추가
      path: '/self-promotion/create',
      element: <PromotionCreate />
    },
    {
      // 프로모션 관리 > 프로모션 수정
      path: '/self-promotion/update/:id',
      element: <PromotionUpdateLayout />
    },
    {
      // 주문 관리
      path: '/order',
      element: <Order />
    },
    {
      // 전체 주문 관리
      path: '/order/all',
      element: <OrderAll />
    },
    {
      // 주문 상세
      path: '/order/detail/:id/v2',
      element: <OrderDetail />
    },
    {
      // 주문 취소 관리
      path: '/order/cancel',
      element: <OrderCancel />
    },
    {
      // 교환 관리
      path: '/order/exchange',
      element: <OrderExchange />
    },
    {
      // 반품관리
      path: '/order/return',
      element: <OrderReturn />
    },
    {
      // 정산관리
      path: '/calculate',
      element: <Calculate />
    },
    {
      // 정산이력
      path: '/calculate-history',
      element: <CalculateHistory />
    },
    {
      // 정산 내역 상세
      path: '/calculate-history/:id',
      element: <CalculateHistoryDetail />
    },
    {
      // 매장 관리
      path: '/vendor',
      element: <Vendor />
    },
    {
      // 쿠폰 관리
      path: '/benefit/coupon',
      element: <Coupon />
    },
    {
      // 쿠폰 생성
      path: '/benefit/coupon/create',
      element: <CouponCreate />
    },
    {
      // 쿠폰 상세
      path: '/benefit/coupon/:id',
      element: <CouponDetail />
    },
    {
      // 이벤트 관리 > 프로모션 관리
      path: '/benefit/promotion',
      element: <EventPromotionList />
    },
    {
      // 이벤트 관리 > 프로모션 상세
      path: '/benefit/promotion/:id',
      element: <EventPromotionDetail />
    },
    {
      // 공지사항
      path: '/notice',
      element: <Notice />
    },
    {
      // 공지사항 상세
      path: '/notice/:id',
      element: <NoticeDetail />
    },
    {
      // 알림 목록
      path: '/notification/list',
      element: <NotificationList />
    },
    {
      // 통계
      path: '/statistics',
      element: <Statistics />
    },
    {
      // 문의하기
      path: '/inquiry',
      element: <Inquiry />
    },
    {
      // 문의하기 상세
      path: '/inquiry/:id',
      element: <InquiryDetail />
    },
    {
      // 문의하기 생성
      path: '/inquiry/create',
      element: <InquiryCreate />
    },
    {
      // 마이 페이지
      path: '/my',
      element: <My />
    }
  ]
};

export default MainRoutes;
