/*
 * Side Bar - 프로모션 관리
 */

// assets
import { IconBoxSeam, IconConfetti, IconGift, IconSpeakerphone } from '@tabler/icons';

// ==============================|| PRODUCT MENU ITEMS ||============================== //

const product = {
  id: 'promotionTab',
  title: '프로모션 관리',
  type: 'group',
  children: [
    {
      id: 'promotionManage',
      title: '셀프 프로모션 관리',
      type: 'item',
      url: '/self-promotion',
      icon: IconGift,
      breadcrumbs: true
    },
    {
      id: '1kmpromotionManage',
      title: '플랫폼 프로모션 관리',
      type: 'item',
      url: '/benefit/promotion',
      icon: IconSpeakerphone,
      breadcrumbs: true
    },
    {
      id: 'todayBottle',
      title: '오늘의 한 병',
      type: 'item',
      url: '/product/today-one-bottle',
      icon: IconConfetti,
      breadcrumbs: true
    },
    {
      id: 'cobuying',
      title: '공동 구매 관리',
      type: 'item',
      url: '/promotion/co-buying',
      icon: IconBoxSeam,
      breadcrumbs: true
    }
  ]
};

export default product;
