/*
 * Side Bar - 정산 관리
 */

// assets
import { IconBrandMessenger } from '@tabler/icons';

// ==============================|| CALCULATE MENU ITEMS ||============================== //

const inquiry = {
  id: 'inquireTab',
  title: '',
  type: 'group',
  children: [
    {
      id: 'inquiryTab',
      title: '문의하기',
      type: 'item',
      url: '/inquiry',
      icon: IconBrandMessenger,
      breadcrumbs: true
    }
  ]
};

export default inquiry;
