import PropTypes from 'prop-types';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) =>
/*
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/order/all', {replace: true})
      // navigate(DASHBOARD_PATH, { replace: true });
    }
  }, [isLoggedIn, navigate]);
*/

   children
;

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
